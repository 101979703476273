//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-180:_4148,_6280,_9880,_4540,_9012,_2280,_5796,_2380;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-180')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-180', "_4148,_6280,_9880,_4540,_9012,_2280,_5796,_2380");
        }
      }catch (ex) {
        console.error(ex);
      }